import "/src/styles/yogaPage.css"
import emailjs from "emailjs-com"
import React, { useState } from "react"
import validator from "validator"

function ConsultForm({ category }) {
  const [showModal, setShowModal] = useState(false)
  const [name, setName] = useState("")
  const [age, setAge] = useState(0)
  const [sex, setSex] = useState("")
  const [occ, setOcc] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phone, setPhone] = useState("")

  const [medHis, setMedHis] = useState("NIL")
  const [pastIllness, setPastIllness] = useState("NIL")
  const [desc, setDesc] = useState("None")

  const [form1, setForm1] = useState("block")
  const [form2, setForm2] = useState("hidden")
  const [errors, setErrors] = useState({
    name: "",
    age: "",
    sex: "",

    address: "",
    contact: "",
  })

  const validateEmail = e => {
    if (validator.isEmail(email)) {
      setEmailError("")
    } else {
      setEmailError("Invalid email")
    }
  }
  const validate1 = () => {
    let newObj = { ...errors }
    if (name.length <= 0) {
      newObj.name = "Name field cannot be empty"
      setErrors(newObj)
      return false
    } else {
      newObj.name = ""
      setErrors(newObj)
    }
    if (email == "") {
      setEmailError("Enter an email")
      return false
    }

    if (age <= 0) {
      newObj.age = "Age required"
      setErrors(newObj)
      return false
    } else {
      newObj.age = ""
      setErrors(newObj)
    }
    if (sex.length <= 0) {
      newObj.sex = "Cannot be empty"
      setErrors(newObj)
      return false
    } else {
      newObj.sex = ""
      setErrors(newObj)
    }
    if (address.length <= 0) {
      newObj.address = "Cannot be empty"

      setErrors(newObj)
      return false
    } else {
      newObj.address = ""
      setErrors(newObj)
    }
    if (phone == 0 || phone > 10000000000) {
      newObj.phone = "Invalid phone number"
      setErrors(newObj)
      return false
    } else {
      newObj.phone = ""
      setErrors(newObj)
    }
    return true
  }
  //   const validate2 = () => {
  //     let newObj = { ...errors }
  //     if (height <= 0) {
  //       newObj.height = "Height required"
  //       setErrors(newObj)
  //       return false
  //     } else {
  //       newObj.height = ""
  //       setErrors(newObj)
  //     }
  //     if (weight <= 0) {
  //       newObj.weight = "Weight required"
  //       setErrors(newObj)
  //       return false
  //     } else {
  //       newObj.weight = ""
  //       setErrors(newObj)
  //     }
  //     return true
  //   }
  const handleNext = () => {
    if (validate1() === true) {
      setForm1("hidden")
      setForm2("block")
    }
  }
  const handleBack = () => {
    setForm1("block")
    setForm2("hidden")
  }

  const handleInitialSubmit = () => {
    setShowModal(true)
  }
  const handleSubmit = e => {
    e.preventDefault()

    var templateParams = {
      category: category,
      name: name,
      age: age,
      sex: sex,
      occ: occ,
      address: address,
      email: email,
      phone: phone,
      height: "N/A",
      weight: "N/A",
      params: "N/A",
      meds: "N/A",
      msg: "N/A",
      surgeries: "N/A",
      reason: "N/A",
      medHis: medHis,
      past: pastIllness,
      desc: desc,
    }
    emailjs
      .send(
        "service_2hs29p7",
        "template_6w9osva",
        templateParams,
        "Ee53uXOqQdY0MCdQ_"
      )
      .then(
        result => {
          console.log(result.text)
          alert("Successfully submitted!")
        },
        error => {
          console.log(error.text)
        }
      )
    setShowModal(false)
  }

  const formClass1 = form1 + " w-full max-w-lg"
  const formClass2 = form2 + " w-full max-w-lg"
  return (
    <>
      <div id="yogaPage">
        <div className="grid grid-cols-12 enrollSection">
          <div className="enroll md:block md:col-start-2 md:col-span-3 ">
            <div className="genInfo ">
              BOOK
              <br /> A
              <br />
              SESSION
              <br />
              <p className="text-sm">
                (You will be contacted from the doctor's <br />
                desk once your submission is recieved )
              </p>
            </div>
          </div>
          <div className="col-start-2 col-span-10 md:col-start-6 md:col-span-7  form1formDiv">
            <div id="contactForm">
              <h1 className="genInfo mb-5 block md:hidden">
                BOOK A SESSION
                <br />
                <p className="text-sm">
                  (You will be contacted from the doctor's desk once your
                  submission is recieved )
                </p>
              </h1>
              <form class={formClass1}>
                <div class="flex flex-wrap  mb-1">
                  <div class="w-full px-3 mb-6  md:mb-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2"
                      for="grid-first-name"
                    >
                      Name
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      onChange={e => {
                        setName(e.target.value)
                      }}
                    />
                    <p className="error">{errors.name}</p>
                  </div>
                  <div class="w-full px-3 grid-row-1 mb-6 md:mb-3">
                    <div class="grid grid-cols-11 ">
                      <div className="col-span-5">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2"
                          for="grid-first-name"
                        >
                          Age
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="number"
                          onChange={e => {
                            setAge(e.target.value)
                          }}
                        />
                        <p className="error">{errors.age}</p>
                      </div>
                      <div className="col-start-7 col-span-5">
                        <label
                          class="block uppercase tracking-wide  text-gray-700 text-xs font-semibold mb-2"
                          for="grid-first-name"
                        >
                          Sex
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="select"
                          onChange={e => {
                            setSex(e.target.value)
                          }}
                        />
                        <p className="error">{errors.sex}</p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full px-3 mb-6 md:mb-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2"
                      for="grid-first-name"
                    >
                      Occupation
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      onChange={e => {
                        setOcc(e.target.value)
                      }}
                    />
                  </div>

                  <div class="w-full px-3 mb-6 md:mb-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      onChange={e => {
                        setAddress(e.target.value)
                      }}
                    />
                    <p className="error">{errors.address}</p>
                  </div>
                  <div class="w-full px-3 grid-row-1 mb-6 md:mb-3">
                    <div class="grid grid-cols-11 ">
                      <div className="col-span-5">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2"
                          for="grid-first-name"
                        >
                          Email
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="email"
                          onChange={e => {
                            setEmail(e.target.value)
                            validateEmail(e)
                          }}
                        />
                        <p className="error">{emailError}</p>
                      </div>
                      <div className="col-start-7 col-span-5">
                        <label
                          class="block uppercase tracking-wide  text-gray-700 text-xs font-semibold mb-2"
                          for="grid-first-name"
                        >
                          Contact no.
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-50 text-gray-700  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="number"
                          onChange={e => {
                            setPhone(e.target.value)
                          }}
                        />
                        <p className="error">{errors.contact}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="md:flex md:items-center">
                  <div class="md:w-1/3">
                    <button
                      class="shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4"
                      type="button"
                      onClick={handleNext}
                    >
                      NEXT
                    </button>
                  </div>
                  <div class="md:w-2/3"></div>
                </div>
              </form>
              <form class={formClass2}>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Medical history
                    </label>
                    <textarea
                      class=" no-resize appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-30 resize-none"
                      id="message"
                      onChange={e => setMedHis(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Past illnesses if any
                    </label>
                    <textarea
                      class=" no-resize appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-30 resize-none"
                      id="message"
                      onChange={e => setPastIllness(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Brief explanation about present complaints
                    </label>
                    <textarea
                      class=" no-resize appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-30 resize-none"
                      id="message"
                      onChange={e => {
                        setDesc(e.target.value)
                      }}
                    ></textarea>
                  </div>
                </div>

                <div class="md:flex md:items-center">
                  <div class="md:w-full">
                    <button
                      class="shadow back focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mr-5"
                      type="button"
                      onClick={handleBack}
                    >
                      BACK
                    </button>
                    <button
                      class="shadow  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={handleInitialSubmit}
                    >
                      SUBMIT
                    </button>
                  </div>
                  <div class="md:w-2/3"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h5 className=" font-semibold">Confirm submission?</h5>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => handleSubmit(e)}
                  >
                    Submit{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default ConsultForm
