import ConsultForm from "../../components/consForm"
import Layout from "../../components/layout"
import "../../styles/consultPage.css"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function Consultation() {
  return (
    <Layout route="/courses">
      <div id="consultPage">
        <div className="grid grid-rows-1 hero">
          <div>
            {" "}
            <h1>AYURVEDIC CONSULTATION</h1>
          </div>
        </div>
        <div className="grid grid-rows-1 genInfoRow">
          <div className="grid grid-cols-12 ">
          <div className="col-start-2 col-span-10 md:col-start-2 md:ml-8 md:col-span-4 genInfo ">
                GENERAL
                <br className="hidden md:block" /> INFO
              </div>
            <div className="col-start-2 col-span-10 md:col-span-6 md:col-start-6 genText ">
              <p>
                Consult experienced doctors for Hair Care, Menstrual Disorders,
                Digestive and Gastric problems
              </p>
              <p>Consult a doctor by booking below! </p>
            </div>
          </div>
        </div>

        <div className="grid grid-rows-1 instructorRow">
          <div className="grid grid-cols-12 ">
            <div className="col-start-2 col-span-10 md:col-span-2 md:col-start-6 mb-7  insHeadMob block lg:hidden">
              MEET YOUR DOCTORS
            </div>

            <div className="col-start-2 col-span-10 md:col-start-2 md:col-span-2 insImg insText">
              {/* <img src={Doctor} alt="Doctor" /> */}
              <StaticImage
                src="../../images/meetInstructor.jpg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr.Anjana Pattali"
                className="inset-0"
              />
              <p>
                <b>Dr Anjana Pattali</b>
                <br />
                BAMS, Msc Yoga Therapy, <br />
                Director , Antaryami
                <br />
              </p>
            </div>
            <div className="col-start-2 col-span-10 md:col-start-5 md:col-span-2 insImg insText">
              {/* <img src={Doctor1} alt="Doctor" /> */}
              <StaticImage
                src="../../images/athiraBAMS.jpg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr. Athira P P"
                className="inset-0"
              />
              <p>
                <b>Dr Athira P P</b>
                <br />
                BAMS <br />
                PN Panicker Souhridha Ayurveda Medical College
                <br />
              </p>
            </div>
            <div className="col-start-2 col-span-10 md:col-start-8 md:col-span-2 insImg insText">
              {/* <img src={Doctor2} alt="Doctor" /> */}
              <StaticImage
                src="../../images/kavyaBAMS.jpeg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr Kavya B Mohan"
                className="inset-0"
              />
              <p>
                <b>Dr Kavya B Mohan</b>
                <br />
                BAMS <br />
                PN Panicker Souhridha Ayurveda Medical College
                <br />
              </p>
            </div>

            <div className="col-start-2 col-span-10 md:col-span-1 md:col-start-11 insHead hidden lg:block ">
              MEET
              <br className="hidden md:block" />
              YOUR
              <br className="hidden md:block" />
              DOCTORS
            </div>
          </div>
        </div>
        <ConsultForm category="Consultation Booking" />
      </div>
    </Layout>
  )
}

export default Consultation
